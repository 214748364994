import request from "@/utils/request.js"

//获取出库记录
export const chainGoodGoodRecord_api1=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/chainGood/freshGoodRecord',
    params
  });
  } 

  //获取非生鲜出库记录
export const storeGoodGoodRecord_api1=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/chainGood/goodRecord',
    params
  });
  } 
  //导出
  
  export const chainGoodExport_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/store/chainGood/export',
      params
    });
    }

//添加库存
// export const getChainGoodAdd_api1=data=>{
//     return  request({
//       method: 'post',
//       url: 'api/v1/store/chainGood/freshAdd',
//       data: data
//     });
//     }
export const getChainGoodAdd_api1=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/chainGood/store',
    data: data
  });
  }
  //添加生鲜商品库(连锁)
  export const getChainGoodAdd_api2 = data => {
    return request({
      method: 'post',
      url: 'api/v1/store/chainGood/freshAdd',
      data: data
    });
  }
   //编辑生鲜商品库(连锁)
   export const freshChangechainGood1 = data => {
    return request({
      method: 'post',
      url: 'api/v1/store/chainGood/freshChange',
      data: data
    });
  }
  //  //删除生鲜商品库(连锁)
  export const chainGoodDelete_api2=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/store/chainGood/freshDelete',
      data: data
    });
    }
//获取生鲜库存商品列表

export const getChainGoodList_api1=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/chainGood/freshList',
    params
  });
  } 

//获取非生鲜库存商品列表
export const getNofreshListGoodList_api1=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/store/chainGood/list',
    params
  });
  } 

//获取库存商品列表(门店)
    
    export const goodSeriesCategory=params=>{
      return  request({
        method: 'get',
        url: 'api/v1/store/chainGood/list',
        params
      });
      }
      
    //获取留存列表
export const getyesterdayGood = params => {
  return request({
    method: 'get',
    url: 'api/v1/store/chainGood/yesterdayGood',
    params
  });
}
//获取商品分类

export const getgoodSeriescategory = params => {
  return request({
    method: 'get',
    url: 'api/v1/store/goodSeries/category',
    params
  });
}